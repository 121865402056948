<template>
  <b-card-code>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table 
    style="white-space: nowrap;
    text-align: center;
    vertical-align : middle;" 
    :columns="columns"
    :rows="payment_history" 
    :rtl="direction" :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }">
      <template slot="table-row" slot-scope="props">
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <span v-else-if="props.column.field === 'created_at'">
          {{ moment(props.row.payment_date).format('YYYY-MM-DD') }}
        </span>
        <span v-else-if="props.column.field === 'month_year'">
          {{ getMonthName(props.row.month_year) }}
        </span>

        <span v-else-if="props.column.field === 'action'">
          <b-button variant="secondary" @click="viewDetails(props.row.payment_type_list)"
            style="padding-left:1cm; float: left; vertical-align: middle;" title="View Payment Type Details">
            <feather-icon class="text-body text-center" icon="EyeIcon" size="15" />
          </b-button>

          <b-button variant="success" style="float: right; vertical-align: middle;"
            @click="downloadPaymentReceipt(props.row.invoice_id, props.row.student_id, props.row.payment_head_name, getMonthName(props.row.month_year))">
            <feather-icon icon="DownloadIcon" class="mr-50" />
          </b-button>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select v-model="pageLength" :options="['10', '15', '20']" class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })" />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
              align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <b-modal ref="payment-view" hide-footer size="sm" :title="$t('Payment Details')">
      <table class="table table-striped table-bordered" style="border-collapse: collapse; border-spacing: 0 10px; ">
        <thead>
          <tr>
            <th>SL#</th>
            <th>Payment Type</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(payment_info, i) in payment_detail_array" :key="i">
            <th scope="row">{{ (i + 1) }}</th>
            <td>{{ payment_info.payment_type_name }}</td>
            <td>{{ payment_info.payment_type_amount }}</td>
          </tr>
        </tbody>

      </table>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButton
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import store from '@/store/index';
import Ripple from 'vue-ripple-directive';
import apiCall from "@/libs/axios";
import moment from "moment";
import download from "downloadjs";

export default {
  name: 'PaymentHistory',
  components: {
    BCardCode,
    VueGoodTable,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Fee Head',
          field: 'payment_head_name',
        },
        {
          label: 'Total Paid',
          field: 'total_amount',
        },
        {
          label: 'Payment for',
          field: 'month_year',
        },
        {
          label: 'Paid At',
          field: 'created_at',
        },
        {
          label: "Receipt",
          field: "action",
        }
      ],
      searchTerm: '',
      status: {
        0: 'Pending',
        1: 'Approved',
        2: 'Declined'
      },
      payment_history: [],
      payment_detail_array: [],
    }
  },
  methods: {
    getMonthName(month_id) {
      var month_name = '';
      if (parseInt(month_id) === 1) {
        month_name = 'January';
      }
      else if (parseInt(month_id) === 2) {
        month_name = 'February';
      }
      else if (parseInt(month_id) === 3) {
        month_name = 'March';
      }
      else if (parseInt(month_id) === 4) {
        month_name = 'April';
      }
      else if (parseInt(month_id) === 5) {
        month_name = 'May';
      }
      else if (parseInt(month_id) === 6) {
        month_name = 'June';
      }
      else if (parseInt(month_id) === 7) {
        month_name = 'July';
      }
      else if (parseInt(month_id) === 8) {
        month_name = 'August';
      }
      else if (parseInt(month_id) === 9) {
        month_name = 'September';
      }
      else if (parseInt(month_id) === 10) {
        month_name = 'October';
      }
      else if (parseInt(month_id) === 11) {
        month_name = 'November';
      }
      else if (parseInt(month_id) === 12) {
        month_name = 'December';
      }

      return month_name;
    },
    viewDetails(payment_detail_list) {
      this.payment_detail_array = payment_detail_list;
      this.$refs['payment-view'].show();
    },
    async getMaterials() {
      await apiCall.get('/student/personal/payment/history').then((response) => {
        this.payment_history = response.data;
      }).catch(() => {
        this.payment_history = [];
      })
    },
    downloadPaymentReceipt(invoice_id, student_id, report_title, month_name) {
      let data = new FormData();
      data.append("invoice_id", invoice_id);
      data.append("student_id", student_id);
      data.append("report_title", report_title);
      data.append("month_name", month_name);

      apiCall
        .post("/download/student/payment/receipt", data, {
          responseType: "blob",
        })
        .then((response) => {
          const content = response.headers["content-type"];
          download(response.data, "student_payment_receipt.pdf", content);
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message);
        });
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getMaterials();
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>